<template>
	<div>
		<div class="indexUser">
			<div>
				<!-- header  点击 -->
				<headers :userList="userList"></headers>
			</div>
			<div class="bg1 userBox">
				<div v-if="state == 0" class="wrap bgw chufang">
					<div class="list">
						<div class="title">我的处方</div>
						<div class="box" v-for="(v, i) in preList" :key="i">
							<img class="fl" :src="v.inquiry_avatar" alt />
							<div class="fl txt">
								<p>
									<span class="c1">{{ v.inquiry_name }}</span>{{v.type|gettype}}
								</p>
								<p>时间：{{ v.add_time }}</p>
								<p class="c1" @click="showInfo(v)">查看详情</p>
							</div>
							<div class="fr">
								<p class="hui" v-if="v.status == 0 || v.status == 1">审核中</p>
								<p class="redc" v-else-if="v.status == 3">已拒绝</p>
								<p class="hui" v-else>已同意</p>
							</div>
						</div>
					</div>
					<div class="fenye" v-if="showPage">
						<el-pagination @current-change="currentChanage" background layout="prev, pager, next" :page-size="15" :total="total"></el-pagination>
					</div>
				</div>
				<div v-if="state == 1" class="wrap bgw chufang">
					<div class="pic_warp">
						<img :src="currentPre.pic" alt />
						
						<div  v-if="currentPre.status ==2||currentPre.status ==1">
							<div class="yp-title">药品信息</div>
							<div class="good_list" >
								<div class="item" v-for="(v, i) in currentPre.goods_list" :key="i">
									<div class="list-title"><div><img :src="v.goods_img" alt=""><span>{{v.goods_name}} {{v.spec}}/{{v.unit}}</span></div><span>x{{v.num}}</span></div>
									<div class="yp-infor">
										<div>
											<div><span>医嘱类型:</span><span>{{v.orderCode}}</span></div>
											<div><span>用药频率:</span><span>{{v.drugFrequency_name}}</span></div>
											<div><span>单次剂量:</span><span>{{v.drugDosage}}</span></div>
											<div><span>总计量:</span><span>{{v.drugIntegraldose}}</span></div>
										</div>
										<div>
											<div><span>计量单位:</span><span>{{v.drugUseUnit_name}}</span></div>
											<div><span>用药天数:</span><span>{{v.drugDay}}</span></div>
											<div><span>用药途径:</span><span>{{v.drugRoute_name}}</span></div>
											<div><span>是否皮试:</span><span>{{v.skinTest=='0'?'不需要皮试':"需要皮试"}}</span></div>
										</div>
									</div>
								</div>
							</div>
							<div class="yp-title">
								审核意见
							</div>
							<div class="ylzf"  v-if="currentPre.status == 2" v-for="v in currentPre.sign_list">
								{{v.audit_opinion}}
							</div>
						</div>
				
						
					
						<div class="ylzf" v-if="currentPre.status == 3">
							审核不通过:{{currentPre.sign_list[0].note}}
						</div>
					
						<div class="tishi">
							<div>
								<button v-if="currentPre.status == 2" @click="fastshop(currentPre.type)">快速购药</button>
							</div>
							<!-- <div v-if="currentPre.status == 3">审核不通过
								<p>拒绝原因：{{currentPre.sign_list[0].note}}</p>
							</div>
							<div v-if="currentPre.status == 0">待审核</div> -->
						</div>
					</div>
				</div>
				<div v-if="state == 2">
					<div class="recordInfo">
						<div class="content">
							<div class="bill_wrap">
								<div class="bill">
									<div class="date">{{ currentPre.add_time }}</div>
									<div class="invald">本处方单3个月内有效</div>
									<h3>处方笺</h3>
									<el-row>
										<el-col :span="12">
											科别:
											<!-- <span>{{ currentPre.inquiry_info.category_name }}</span> -->
										</el-col>
										<el-col :span="12">
											处方号:
											<span>{{ currentPre.p_code }}</span>
										</el-col>
									</el-row>
									<el-row>
										<el-col :span="8">
											姓名:
											<span>{{ currentPre.inquiry_info.realname }}</span>
										</el-col>
										<el-col :span="8">
											年龄:
											<span>{{ currentPre.inquiry_info.age }}</span>
										</el-col>
										<el-col :span="8">
											性别:
											<span>{{ currentPre.inquiry_info.sex === 0 ? "保密" : (currentPre.inquiry_info.sex == 1 ? "男" : "女")}}</span>
										</el-col>
									</el-row>
									<el-row>
										<el-col :span="12">
											过敏史:
											<span>{{ currentPre.inquiry_info.allergy }}</span>
										</el-col>
										<el-col :span="12">
											遗传史:
											<span>{{ currentPre.inquiry_info.genetic_disease}}</span>
										</el-col>
									</el-row>
									<el-row>
										临床诊断:
										<span>{{ currentPre.inquiry_info.diagnosis }}</span>
									</el-row>
									<div class="content">
										<div class="type">R</div>
										<div class="name" v-for="(item, i) in currentPre.goods_list" :key="i">
											<el-row>
												<el-col :span="8">{{ item.goods_name }}</el-col>
												<el-col :span="8">{{item.spec}}</el-col>
												<el-col :span="8">x{{item.num}} {{item.unit}}</el-col>
												<el-col :span="8">用法</el-col>
												<el-col :span="8">{{ item.drugRoute_name}}</el-col>
												<el-col :span="8">每次{{item.drugDosage}}{{item.drugUseUnit_name}}</el-col>
											</el-row>
										</div>
									</div>

									<div class="graph">
										<el-row>
											<el-col :span="12" v-if="doctorSign.audit_sign">
												<p>医生签字:</p>
												<img :src="doctorSign.audit_sign" alt />
											</el-col>
											<el-col :span="12" v-if="pharmSign.audit_sign">
												<p>药师签字:</p>
												<img :src="pharmSign.audit_sign" alt />
											</el-col>
										</el-row>
										<el-row>
											<el-col :span="12" v-if="deploySign.audit_sign">
												<p>调配签字:</p>
												<img :src="deploySign.audit_sign" alt />
											</el-col>
											<el-col :span="12" v-if="checkSign.audit_sign">
												<p>复核签字:</p>
												<img :src="checkSign.audit_sign" alt />
											</el-col>
										</el-row>
									</div>
								</div>
								<div class="tishi">   
									<div>
										<button v-if="currentPre.status == 2" @click="fastshop(currentPre.type)">快速购药</button>
									</div>
									<div v-if="currentPre.status == 3">已拒绝：{{currentPre.sign_list[1].note  }}</div>
									<div v-if="currentPre.status == 0">待审核</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="choiceshop" v-show="isdoctor">
					<div class="shopbox">
						<div class="title">
							选择药店
							<div class="closebox el-icon-close" @click="isdoctor=false"></div>
						</div>
						<div class="choicecontent">
							<div class="shopboxlist" v-for="(item,index) in shoplist" :key="index">
								<div>
									<el-radio v-model="radio" :label="index">{{item.shop_name}}</el-radio>
								</div>
								<div class="drugsbox" v-for="(value,key) in item.goods_list" :key="key">
									<img :src="value.goods_img" alt="">
									<div class="rightCot">
										<p class="drugs">{{value.goods_name}}</p>
										<p class="price">￥{{value.price}}</p>
									</div>
								</div>
							</div>
						</div>
						<div class="choicebottom">
							<div class="button" @click="submit">提交</div>
						</div>
					</div>
				</div>
			</div>
			<div>
				<publicBottom></publicBottom>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from "./header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部
	import {
		usergetinfo
	} from "@/request/user";
	import {
		getPrescriptionList
	} from "@/request/user";
	import {
		getInfo
	} from "@/request/user";
	import {
		add_cart
	} from "@/request/index"; //加入购物车
	export default {
		components: {
			headers,
			publicBottom
		},
		data() {
			return {
				userList: [], // 个人信息
				uploadList: [],
				preList: [], // 处方列表
				showPage: true,
				total: 100,
				state: 0,
				currentPre: {},
				note: "",
				doctorSign: {}, // 医生签字对象
				pharmSign: {}, // 药师签字对象
				deploySign: {}, // 调配签字对象
				checkSign: {}, // 复核签字对象
				shoplist: "",
				isdoctor: false,
				radio: -1,
			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}
			this.init();
			this.getPagePrescriptionList();
		},
		filters: {
			gettype(value) {
				switch (value) {
					case 0:
						return "问诊处方"
						break;
					case 1:
						return "用户上传处方单"
						break;
					case 2:
						return "医生上传处方单"
						break;
					default:
						break;
				}
			},
		},
		methods: {
			submit() {
				if (this.radio == -1) {
					this.$message.error("请选择药店");
					return;
				}
				this.$axios.post("/api/goods_cart/prescriptionToCart", {
					shop_id: this.shoplist[this.radio].shop_id,
					token: this.$token,
					p_code: this.currentPre.p_code,
				}).then(res => {
					console.log(res.status);
					if (res.status == 200) {
						this.radio = "-1",
							this.isdoctor = false;
						this.$message.success("添加购物车成功")
					} else {
						this.$message.error("添加购物车失败")
					}
				});
			},
			fastshop(type) {
				console.log(type);
				if (type == 1) {
					for (let item of this.currentPre.goods_list) {
						this.$axios.post("/api/goods_cart/prescriptionToCart", {
							p_code: this.currentPre.p_code,
							shop_id: item.shop_id,
							token: this.$token
						}).then(res => {
							if (res.data.code == 1) {
								this.$message.success("操作成功,该商品已加入购物车");
							}
						})
					}
				} else {
					this.getshop();
				}
			},
			// 获取药店列表
			getshop() {
				this.$axios.post("/api/shop/selectShop", {
					p_code: this.currentPre.p_code,
					token: this.$token,
				}).then(res => {
					if (res.data.code == 1) {
						this.shoplist = res.data.data;
						console.log(this.shoplist);
						if (this.shoplist.length == 0) {
							this.$message.error("未找到相关药店");
						} else {
							this.isdoctor = true;
						}
					}
				});
			},
			getPagePrescriptionList() {
				getPrescriptionList({
					token: this.$token,
					status_str: "0,1,2,3",
					page: 1
				}).then(res => {
					if (res.code == 1) {


						this.preList = res.data.data;
						console.log(this.preList);
						this.total = res.data.total;
						this.showPage = res.data.last_page !== 1;
					}
				});
			},
			init() {
				//   个人信息
				usergetinfo({
					token: this.$tokens
				}).then(res => {
					this.userList = res.data;
				});
			},
			currentChanage(n) {
				getPrescriptionList({
					token: this.$token,
					status_str: "0,1,2,3",
					page: n
				}).then(res => {
					if (res.code == 1) {
						this.preList = res.data.data;
						console.log(this.preList);
						this.total = res.data.total;
						this.showPage = res.data.last_page !== 1;
					}
				});
			},
			showInfo(v) {
				//  查看处方单详情。

				getInfo({
					token: this.$token,
					p_code: v.p_code
				}).then(res => {
					if (res.code == 1) {
						if (res.data.type == 1||res.data.type==2) {
							// 图片处方
							this.currentPre = res.data;
							console.log(this.currentPre);
							this.state = 1;
							if (this.currentPre.sign_list) {
								this.note = this.currentPre.sign_list.forEach(item => {
									if (item.cert_type == 3) this.note = item.note;
								});
							}
						} else if (res.data.type == 0) {
							this.state = 2;
							this.currentPre = res.data;
							console.log(this.currentPre);
							this.currentPre.sign_list.forEach(item => {
								if (item.cert_type == 1 || item.cert_type == 2) {
									this.doctorSign = item;
								} else if (item.cert_type == 3) {
									this.pharmSign = item;
								} else if (item.cert_type == 4) {
									this.deploySign = item;
								} else if (item.cert_type == 5) {
									this.checkSign = item;
								}
							});
						}
					}
				});
			}
		}
	};
</script>

<style lang="less" scoped>
	.ylzf{
		background-color: #f5f5f5;
		height: 100px;
		padding: 20px;
		text-align: start;
	}
	.yp-title{
		line-height: 40px;
		background-color:rgba(78, 238, 200, 1);
		color:#fff;
		text-align: start;
		padding: 0 20px;
		margin-top: 20px;
	}
	.choiceshop {
		position: fixed;
		width: 100%;
		height: 100%;
		top: 0;
		z-index: 999;
		background: rgba(0, 0, 0, 0.5);

		.shopbox {
			width: 600px;
			height: 502px;
			background-color: #fff;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			.title {
				text-align: center;
				line-height: 40px;
				font-size: 17px;
				border-bottom: 1px solid #EAEAEA;
				position: absolute;
				width: 100%;
				background-color: #fff;
				top: 0;
				left: 0;
				z-index: 99;

				.closebox {
					position: absolute;
					right: 20px;
					top: 10px;
					font-size: 20px;
					cursor: pointer;
				}

			}

			.choicecontent {
				height: 460px;
				width: 100%;
				padding-top: 40px;
				overflow: auto;
			}

			.choicebottom {
				.button {
					width: 100px;
					text-align: center;
					margin: 0 auto;
					padding: 5px 10px;
					margin-top: 4px;
					border-radius: 5px;
					border: 1px solid #FF0000;
					color: red;
				}
			}
		}
	}

	.shopboxlist {
		padding: 20px;

		.drugsbox {
			display: flex;
			border-bottom: 1px solid #CCCCCC;
			margin: 10px 0;
			padding: 10px 0;

			img {
				width: 80px;
				height: 80px;
			}

			.rightCot {
				margin-left: 30px;

				.price {
					margin-top: 30px;
					color: red;
				}
			}
		}
	}

	.tishi {
		position: relative;
		height: 50px;
		margin-bottom: 20px;
		line-height: 50px;

		>div {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			font-size: 26px;
			font-weight: 700;
			color: #CCCCCC;
		}

		button {
			// padding: px 20px;
			height: 50px !important;
			line-height: 50px !important;
			width: 150px !important;
			text-align: center;
			font-size: 18px;
		}
	}

	.list {
		.title {
			height: 69px;
			line-height: 69px;
			font-size: 18px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: rgba(78, 238, 200, 1);
			padding-left: 36px;
			border-bottom: 1px solid #e6e6e6;
		}

		.box {
			height: 170px;
			padding-left: 30px;
			padding-right: 30px;
			padding-top: 30px;
			border-bottom: 1px solid #e6e6e6;

			img {
				width: 110px;
				height: 110px;
				margin-right: 32px;
			}

			p {
				font-size: 18px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: rgba(49, 49, 49, 1);
			}

			.txt {
				span {
					margin-right: 30px;
				}

				p {
					margin-bottom: 16px;
				}

				p:nth-of-type(2) {
					margin-bottom: 24px;
				}
			}

			.c1 {
				color: #4eeec8;
				cursor: pointer;
			}

			.redc {
				color: #f32f2f;
			}

			.hui {
				color: #a8a8a8;
			}
		}
	}

	.chufang {
		min-height: 650px;
		padding-bottom: 80px;
		position: relative;
	}

	.fenye {
		position: absolute;
		bottom: 20px;
		text-align: center;
		width: 100%;
	}

	.pic_warp {
		text-align: center;

		img {
			width: 400px;
			margin-top: 40px;
		}

		>div {
			button {
				width: 130px;
				height: 55px;
				line-height: 55px;
				font-size: 20px;
				margin-top: 30px;
				cursor: pointer;
			}
		}
	}

	.good_list {
		margin: 15px auto;
		.item{
			padding: 20px;
			background-color: #f5f5f5;
			margin: 20px;
			border-radius: 10px;
			.list-title{
				height: 60px;
				line-height: 50px;
				display: flex;
				border-bottom:1px solid #4EEEC8;
				justify-content: space-between;
				position: relative;
				padding:0px 80px;
				img{
					width: 50px;
					height: 50px;
					position: absolute;
					margin: 0;
					left: 0;
				}
			}
			.yp-infor{
				display: flex;
				justify-content: space-between;
				flex-direction: column;
				div{
					display: flex;
					justify-content: space-between;
					div{
						margin-top: 20px;
						// margin-bottom: 20px;
						span:nth-child(1){
							display: inline-block;
							margin-top: 5px;
							width: 100px;
							text-align: start;
						}
						span:nth-child(2){
							display: inline-block;
							background-color: #fff;
							border:1px solid #4EEEC8;
							padding: 5px;
							width: 100px;
							text-align: start;
						}
					}
				}
			}
		}
	}

	.recordInfo {
		width: 1200px;
		min-height: 1169px;
		background: #fff;
		overflow: hidden;
		margin: 50px auto;
	}

	.bill {
		box-sizing: border-box;
		box-shadow: 5px 5px 5px #ccc;
		width: 702px;
		min-height: 922px;
		margin-left: 249px;
		margin-top: 66px;
		margin-bottom: 66px;
		background: #f5f5f5;
		font-size: 16px;
		border: 1px solid #ccc;
		padding: 20px;

		.date {
			margin-bottom: 5px;
			font-size: 24px;
			color: #010101;
		}

		.invald {
			font-size: 24px;
			color: #010101;
		}

		h3 {
			font-size: 30px;
			font-weight: 500;
			color: #010101;
			text-align: center;
			margin-bottom: 15px;
		}

		>.el-row {
			line-height: 50px;
			font-size: 24px;
			color: #000000;
			font-weight: 500;

			span {
				font-weight: 400;

				font-size: 22px;
				color: #616161;
			}
		}
	}

	.content {
		min-height: 200px;
		overflow: hidden;
		border-bottom: 1px solid #424242;

		.el-row {
			border: none;
			font-size: 22px;
			color: #616161;
		}

		.el-row {
			.el-col {
				margin: 3px 0;
			}

			.el-col:nth-child(2) {
				text-align: center;
			}

			.el-col:nth-child(4) {
				text-indent: 2em;
			}

			.el-col:nth-child(5) {
				text-align: center;
			}

			.el-col:nth-child(3) {
				text-align: right;
			}

			.el-col:nth-child(6) {
				text-align: right;
			}
		}

		.type {
			font-size: 22px;
			font-weight: 700;
		}

		.name {
			margin: 3px 0;
		}
	}

	.graph {
		font-size: 24px;
		margin-top: 40px;

		.el-row {
			margin-top: 18px;

			img {
				width: 320px;
				min-height: 200px;
			}
		}
	}
</style>
